
<template lang="pug">
.wrapper.i-wrap
  LeftBar
  
  .section-mid
    .star-wrap(v-if="isLoading" style="width:100%; height:200px;display:flex; justify-content:center;align-items:center;")
      Spinner
    transition(name="slideup")
      .star-wrap(v-if="!isLoading")
        .starTop
          
          div
            .edit-wrap(style="text-align:center;" v-if="user && !isLoading")
              router-link(v-if="user.adminLevel === 'admin'|| user.adminLevel === 'editor' " :to="`/admin/staredit/${star._id}`") Edit star
            img.poster(v-bind:src="`${star.poster}`")
          .starRight
            span(v-if="star.nameCH")
              h1 {{star.nameCH}} 
                FavoriteButton(
                  :id="star._id"
                  :type="star.model"
                )
              h2 {{star.nameEN}} 
            span(v-else)
              h1 {{star.nameEN}} 
                FavoriteButton(
                  :id="star._id"
                  :type="star.model"
                )               
            .starStats
              span 生日:
                span(v-if="star.dob") &nbsp; {{star.dob| luxon('MMMM月 dd日 yyyy')}} 
                br
                span {{star.age}}歲 
                br
                span(v-if="star.dod") &nbsp; {{star.dod| luxon('MMMM月 dd日 yyyy')}}
              span 星座: {{this.star.zodiac}} 
              br
              
            .highAndLow
              //- Highest and lowest movies

            .starSummary
              v-clamp(autoresize :max-lines="7" style="line-height:28px") {{star.summary}}
                template(#after="{toggle, expanded, clamped}")
                  span.pointer(
                    v-if="expanded || clamped"
                    class="toggle btn btn-sm"
                    @click="toggle") <br> <div style="text-align:right;">{{clamped ? '更多' : '較少'}}</div>
                  
        .starMid
          .mtes-tab-wrap
            #overview.pointer(v-bind:class="[{active: tab =='movieCast'}, {active: tab ==undefined}]" @click="tab ='movieCast'") 電影演員
            #overview.pointer(v-bind:class="[{active: tab =='movieCrew'}, {active: tab ==undefined}]" @click="tab ='movieCrew'") 電影工作人員
            #tabCast.pointer(v-bind:class="{active: tab =='tvCast'}" @click="tab ='tvCast'") 電視劇演員
            #tabCast.pointer(v-bind:class="{active: tab =='tvCrew'}" @click="tab ='tvCrew'") 電視劇工作人員
          //- h3 Images
        .starBot
          //- .bestWorstWrap
            h3 Best Movie
          transition(name="")
            .movie-cast-tab(v-if="tab == 'movieCast'")
              mtesList(
                :items="cast.movie"
                :listName="'電影演員'"
                :type="`movie`"
                :showLessNumber="10"
                v-if="cast.movie.length > 0"
              )
              .star-tab-empty(v-else) 沒有任何
          transition(name="")
            .tv-cast-tab(v-if="tab == 'movieCrew'")
              mtesList(
                :items="crew.movie"
                :listName="'電影工作人員'"
                :type="`movie`"
                :showLessNumber="5"
                v-if="crew.movie.length > 0"
              )
              .star-tab-empty(v-else) 沒有任何
          transition(name="")
            .movie-crew-tab(v-if="tab == 'tvCast'")
              mtesList(
                :items="cast.tvSeries"
                :listName="'電視劇演員'"
                :type="`tvSeries`"
                :showLessNumber="5"
                v-if="cast.tvSeries.length > 0"
              )
              .star-tab-empty(v-else) 沒有任何
          transition(name="")
            .tv-crew-tab(v-if="tab == 'tvCrew'")
              mtesList(
                :items="crew.tvSeries"
                :listName="'電視劇工作人員'"
                :type="`tvSeries`"
                :showLessNumber="5"
                v-if="crew.tvSeries.length > 0"
              )
              .star-tab-empty(v-else) 沒有任何
        .mtes-right
  .section-right
</template>

<script>
import mtesList from '@/components/mtesList';
import FavoriteButton from '@/components/FavoriteButton';
import VClamp from 'vue-clamp'
import Spinner from 'vue-simple-spinner'
import { mapState } from "vuex";
import LeftBar from '@/components/LeftBar'
export default {
  name: 'Star',
  components:{
    LeftBar,
    mtesList,
    VClamp,
    Spinner,
    FavoriteButton,
  },
  props:{
    id:String
  },
  computed:{
    ...mapState(['user']),
  },
  data(){
    return{
      isLoading:true,
      star:null,
      cast:null,
      crew:null,
      bestMovie:null,
      worstMovie:null,
      // loaded:false,
      starSummary:false,
      lines: 3,
      tab:'movieCast',
      showLessCast:true,
    }
  },
  
  methods:{
    toggleClamp(){
      this.lines > 0 ? this.lines = 0 : this.lines = 3;
      this.starSummary = !this.starSummary;
    },
  },
  async created(){
    var response = await this.axios.get(`/api/star/${this.id}`)
    this.star = response.data.star;
    var bday = new Date(this.star.dob)
    var month = bday.getMonth()+1
    var day = bday.getDay()

    function calculate_age(dob) { 
      var diff_ms = Date.now() - dob
      var age_dt = new Date(diff_ms);     
      return  Math.abs(age_dt.getUTCFullYear() - 1970);
    }

    /**
     * Return zodiac sugn by month and day
     * @param day
     * @param month
     * @return {string} name of zodiac sign
     */
    var zodiac = await getZodiacSign(day,month)
    function getZodiacSign(day, month) {

      var zodiacSigns = {
        'capricorn':'Capricorn 摩羯座 ♑',
        'aquarius':'Aquarius 水瓶座 ♒',
        'pisces':'Pisces 雙魚座 ♓',
        'aries':'Aries 白羊座 ♈	',
        'taurus':'Taurus 金牛座 ♉	',
        'gemini':'Gemini 雙子座 ♊ ',
        'cancer':'Cancer 巨蟹座 ♋',
        'leo':'Leo 獅子座 ♌',
        'virgo':'Virgo 處女座 ♍',
        'libra':'Libra 天秤座 ♎',
        'scorpio':'Scorpio 天蠍座 ♏',
        'sagittarius':'Sagittarius 射手座 ♐'
      }

      if((month == 1 && day <= 20) || (month == 12 && day >=22)) {
        return zodiacSigns.capricorn;
      } else if ((month == 1 && day >= 21) || (month == 2 && day <= 18)) {
        return zodiacSigns.aquarius;
      } else if((month == 2 && day >= 19) || (month == 3 && day <= 20)) {
        return zodiacSigns.pisces;
      } else if((month == 3 && day >= 21) || (month == 4 && day <= 20)) {
        return zodiacSigns.aries;
      } else if((month == 4 && day >= 21) || (month == 5 && day <= 20)) {
        return zodiacSigns.taurus;
      } else if((month == 5 && day >= 21) || (month == 6 && day <= 20)) {
        return zodiacSigns.gemini;
      } else if((month == 6 && day >= 22) || (month == 7 && day <= 22)) {
        return zodiacSigns.cancer;
      } else if((month == 7 && day >= 23) || (month == 8 && day <= 23)) {
        return zodiacSigns.leo;
      } else if((month == 8 && day >= 24) || (month == 9 && day <= 23)) {
        return zodiacSigns.virgo;
      } else if((month == 9 && day >= 24) || (month == 10 && day <= 23)) {
        return zodiacSigns.libra;
      } else if((month == 10 && day >= 24) || (month == 11 && day <= 22)) {
        return zodiacSigns.scorpio;
      } else if((month == 11 && day >= 23) || (month == 12 && day <= 21)) {
        return zodiacSigns.sagittarius;
      }
    }
    this.star.age = calculate_age(bday)
    this.star.zodiac = zodiac
    this.cast = response.data.cast;
    this.crew = response.data.crew;
    
    // CAST SORT BY US RELEASE DATE
    for(let i = 0; i < this.cast.movie.length; i++){
      this.cast.movie[i].releaseDateUS = new Date(this.cast.movie[i].movie.releaseDateUS)
    }
    this.cast.movie.sort((a, b) =>{  
      return b.releaseDateUS - a.releaseDateUS
    })
    // for(let i = 0; i < this.cast.tvSeries.length; i++){
    //   this.cast.tvSeries[i].releaseDateUS = new Date(this.cast.tvSeries[i].movie.releaseDateUS)
    // }
    // this.cast.tvSeries.sort((a, b) =>{  
    //   return b.releaseDateUS - a.releaseDateUS
    // })
    // CREW SORT BY US RELEASE DATE
    for(let i = 0; i < this.crew.movie.length; i++){
      this.crew.movie[i].releaseDateUS = new Date(this.crew.movie[i].movie.releaseDateUS)
    }
    this.crew.movie.sort((a, b) =>{  
      return b.releaseDateUS - a.releaseDateUS
    })
    // for(let i = 0; i < this.crew.tvSeries.length; i++){
    //   this.crew.tvSeries[i].releaseDateUS = new Date(this.crew.tvSeries[i].movie.releaseDateUS)
    // }
    // this.crew.tvSeries.sort((a, b) =>{  
    //   return b.releaseDateUS - a.releaseDateUS
    // })


    // JOBS
    var jobs = []
    
    // BEST MOVIE
    var bestMovieArr=[]
    for(let i=0; i < this.cast.movie.length; i++){
      // console.log(this.cast.movie[i].movie)
      bestMovieArr.push(this.cast.movie[i].movie)
    }
    // console.log(bestMovieArr)
    this.isLoading = false;
    
  },
  jsonld(){
    var x = this.isLoading;
    
    var jsonldInfo = {
      "@context": "http://schema.org/",
      "@type": "Person",
      "name": '',      
      "description": "",
      "url": "",
      "image":"",
    };
    if (!x){
      jsonldInfo.description = `${this.star.summary}` || ''
      jsonldInfo.url = `https://stinkytofu.tw/star/${this.star._id}`
      jsonldInfo.image = `${this.star.poster}`
      
      if (this.star.nameCH){
        jsonldInfo.name = this.star.nameCH
      }else{
        jsonldInfo.name = this.star.nameEN
      }
    }
    return jsonldInfo
  },
  metaInfo() {
    var x = this.isLoading;
    var theName = '';
    if (!x){
      if (this.star.nameCH){
        theName = this.star.nameCH
      }else{
        theName = this.star.nameEN
      }
      var metaStuff = [
        {property: 'og:title', content: theName},
        {property: 'og:type', content: 'profile'},
        {property: 'og:url', content: `https://stinkytofu.tw/star/${this.star._id}`},
        {property: 'og:description', content: `${this.star.summary}` || ' ' },
        {property: 'og:image', content: `${this.star.poster}`},
        {property: 'twitter:image:src', content: `${this.star.thumbnail}`},
        {property: 'og:locale', content: 'zh_TW'},
        // {property: 'og:image:width', content: '1000'},
        {property: 'og:site_name', content: '臭豆腐電影'},
      ]
    }
    return {
      meta:metaStuff,
      title: this.isLoading ? 'Loading...': theName,
      titleTemplate: "%s - 臭豆腐 - 電影∣影集∣評分∣評論"
    }    
  },
}
</script>
<style lang="scss" scoped>
.mtes-tab-wrap{
    margin:20px 0;
    display:flex;
    justify-content: center;

    div{
      background:transparent;
      margin:0 10px;
      border:solid 1px lightgrey;
      padding:1px 20px;
      border-radius: 3px;
      transition: background .3s, border .3s;
      &.active{
        background:white;
        border:solid 1px grey;
      }
    }
  }
.star-tab-empty{
  padding:50px;
  text-align:center;
}
.starStats{
  margin:20px 0 10px 0 ;
}
.starSummary{
  margin:20px 10px;
  p{
    max-height: 200px;
    transition:max-height .3s;
    white-space: wrap;
    word-break: normal !important;
  }
  span{
    text-align: right;
    width: 100%;
    display: inline-block;
  }
  p.active{
    max-height:500px;
  }
}
.starBot{
  width:100%;
  padding:10px;
  background:white;
  border-radius:10px;
  box-shadow:8px 6.4px 38.4px rgba(39,44,49,0.06), 1px 3.2px 8px rgba(39,44,49,0.03);
}


  .starStats{
    // display:flex;
    span{
      margin:0 10px;
    }
  }
  .starWrap{
    width:950px;
    margin:0 auto;
  }
  .starTop{
    // width:00px;
    margin:20px 0;
    display:flex;
    .starRight{
      text-align:left;
    }
    .poster{
      width:250px;
      margin:0 20px;
    }
  }

  @media screen and(max-width:1024px) {
    .starTop{
      flex-direction: column;
      align-items: center;
      text-align: center;
      .starRight{
        text-align:center;
      }
    }
  }
  @media screen and (max-width:768px) {
    .mtes-tab-wrap{
      font-size:16px;
      text-align:center;
      div{
        padding:1px 5px;
      }
    }
  }
</style>
